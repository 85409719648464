import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import servicesData from '../Models/services.json';
import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";

const BookAppointment = () => {

    const [loadingButton, setLoadingButton] = useState(false);

    const handleOnSubmitForm = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('book-an-appointment-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                alert("Success!");
                setLoadingButton(false);
                form.reset();
            }).catch((error) => {
            alert("Failure!");
            setLoadingButton(false);
            form.reset();
            console.log(error)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className="container-fluid">
            {/*hero section*/}
            <div className="row bg-book-appointment-container">
                <div className="col-12">

                    {/*nav bar*/}
                    <div className="row justify-content-center pb-4 ">
                        <NavbarPage/>
                        <div className="col-12 p-0 position-fixed">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse mt-4" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex justify-content-between align-content-start gap-2 flex-column">*/}
                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle services-container font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="/all-services-details.html" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu bg-color-navbar services-details d-none font-family-raleway" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                About*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className="btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                Contact us*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*hero section*/}
                    <div className="row">
                        <div className="col-12">
                            <div className="my-4 py-4"></div>
                            <div className="my-4 py-4"></div>
                            <div className="my-4 py-md-3 py-4 "></div>
                            {/*<div className="my-4 py-4 d-none d-md-block"></div>*/}
                            {/*<div className="my-4 py-4 d-none d-md-block"></div>*/}
                        </div>
                    </div>
                </div>
            </div>


            {/*appointment section*/}
            <div className="row bg-color-container">
                <div className="col-12">
                    <div className="row book-appointment-container justify-content-center px-4">
                        <div className="co-12 col-md-10 p-0">
                            <div className="row bg-white book-appointment-card-container ">
                                <div className="col-12 col-md-5 p-0">
                                    <img className="img-fluid w-100 book-appointment-image"
                                         src="/images/book-appointment/book-appointment-lady-image.png"
                                         alt="lady image"/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="p-4">
                                        <div className="text-center pb-4">
                                            <label className="font-size-32 font-color-gold"> Book An Appointment</label>
                                        </div>
                                        <div className="row justify-content-center pb-4">
                                            <div className="col-12 line-book w-25"></div>
                                        </div>
                                        <form className="text-center" id="book-an-appointment-form" method="POST"
                                              onSubmit={handleOnSubmitForm}
                                              action="https://script.google.com/macros/s/AKfycbwAk6RT7dugCLOb9oCy0R8t5gA5e6RwdfvES8nVZUoQ_z3TwEKuF3UXU6IfZ61nBfqf/exec">
                                            <div className="form-group">
                                                <input type="text" id="Name" name="Name" required placeholder="Name"
                                                       className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0"/>
                                            </div>
                                            <div className="form-row row">
                                                <div className="col-12 form-group col-md-6 py-2">
                                                    <input type="text" id="email" name="Email" required
                                                           placeholder="Email"
                                                           className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0"/>
                                                </div>
                                                <div className="form-group col-md-6 py-2">
                                                    <input type="text" name="PhoneNumber" required id="phoneNumber"
                                                           placeholder="Mobile Number"
                                                           className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0"/>
                                                </div>
                                            </div>
                                            <div className="form-row row">
                                                <div className="col-12 form-group col-md-6 py-2">
                                                    <select id="services" required name="SelectedServices"
                                                            className="form-control bg-transparent py-2 input-border-color border-top-0 border-start-0 border-end-0">
                                                        <option className="font-color-brown font-family-raleway"
                                                                selected disabled="disabled" value="">Select
                                                        </option>
                                                        {servicesData.map(eachService => (
                                                            <option className="font-color-brown font-family-raleway"
                                                                    value={eachService.service_name}>{eachService.service_name}</option>
                                                        ))}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Hair Styling">Hair Styling</option>*/}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Nails">Nails</option>*/}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Spa">Spa</option>*/}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Skin Care">Skin Care</option>*/}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Skin Care">Make Up</option>*/}
                                                        {/*<option className="font-color-brown font-family-raleway" value="Skin Care">Waxing</option>*/}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6 py-2">
                                                    <select id="location" required name="Location"
                                                            className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0">
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="" selected disabled={true}>Select
                                                        </option>
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="Madhapur">Madhapur
                                                        </option>
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="Gachibowli" disabled={true}>Gachibowli (coming
                                                            soon)
                                                        </option>
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="Ameerpet" disabled={true}>Ameerpet (coming soon)
                                                        </option>
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="Nagole" disabled={true}>Nagole (coming soon)
                                                        </option>
                                                        <option className="font-color-brown font-family-raleway"
                                                                value="Raidurg" disabled={true}>Raidurg (coming soon)
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row row">
                                                <div className="col-12 form-group col-md-6 py-2">
                                                    <input type="date" name="Date" required id="date" placeholder="Date"
                                                           className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0"/>
                                                </div>
                                                <div className="form-group col-md-6 py-2">
                                                    <input type="time" required name="Time" id="time" placeholder="Time"
                                                           className="form-control py-2 bg-transparent input-border-color border-top-0 border-start-0 border-end-0"/>
                                                </div>
                                            </div>
                                            <div className="form-group py-2">
                                                <textarea rows="3" name="Message"
                                                          className="form-control bg-transparent input-border-color border-top-0 border-start-0 border-end-0"
                                                          id="messageArea" placeholder="Type your message"
                                                          aria-label="With textarea"></textarea>
                                            </div>
                                            <div className="pt-2">
                                                <button type="submit" disabled={loadingButton}
                                                        className="button-bg text-white font-size-14 font-weight-500p font-family-raleway">
                                                    {!loadingButton ? "Book" :
                                                        <TailSpin height={20} width={20} strokeWidth={3} color={'#FFFFFF'}/>}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row bg-color-container">
                <div className="col-12 py-4 my-4"></div>
            </div>


            {/*footer section*/}
            <FooterPage/>
        </div>
    )
}

export default BookAppointment
